:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}

.center-elements-horizontally {
    display: flex;
    justify-content: center;
    width: 100%;
}
